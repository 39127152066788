<template>
  <div class="nav-main">
    <template v-for="item,index in list">
      <div :key="index" @click="navBut(item)" :class="[item.series==code?'nav-act':'','nav-list']">
        <div class="show-rectangle" v-if="item.series==code"></div>
        <img :src="item.series==code?img.nav[item.actImg]:img.nav[item.img]" alt="">
        <span> {{item.name}}</span>
        <Icon type="ios-arrow-forward" v-if="item.children.length>0" :class='[item.series==code?"bule":"grey","codeIcon"]'></Icon>
        <div class="levelBox" v-if="item.children.length>0">
          <div v-for="el in item.children" :key="el.code" class="levelBox-list" @click.stop="nav2But(el)">
            <img :src="img.nav2[el.img]" alt="">
            <span>{{el.name}}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'navPage',
  components: {
  },
  data() {
    return {
      ticker: null,
    }
  },
  props: {
    list: {
      type: Array,
      default() { return []; }
    },
    code: {
      type: Number,
      default: 0,
    },
  },
  created: function () {

  },
  computed: {

  },
  watch: {

  },
  mounted: function () {
    //isSite 平台   isOwner 项目管理者
  },
  methods: {
    nav2But(el) {
      if (el.router == this.$route.path) return;
      this.$emit('saved', el);
      this.$store.commit("sys/setMenusId", el.id);
    },
    navBut(el) {
      if (el.router == this.$route.path) return;
      this.$emit('saved', el);
      this.$store.commit("sys/setMenusId", el.id);
    }
  },

}
</script>
<style>
.nav-main {
  width: 100%;
}
.nav-list {
  width: 264px;
  height: 48px;
  color: #2f3443;
  align-items: center;
  line-height: 48px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
}
.nav-list:hover,
.nav-act {
  background: rgba(63, 117, 255, 0.1);
  border-radius: 8px;
  color: #3f75ff;
}
.nav-list img {
  margin: 0 20px 0 38px;
}
.show-rectangle {
  width: 6px;
  height: 48px;
  background: #3f75ff;
  border-radius: 0 4px 4px 0;
  position: absolute;
  left: 0;
  top: 0;
}
.bule {
  color: #3f75ff;
  /* animation-name: rotateOnce;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: 1; */
}
@keyframes rotateOnce {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
}
.grey {
  color: #8b94ae;
}
.nav-list:hover .levelBox {
  display: block;
}
.levelBox {
  display: none;
  position: absolute;
  width: 172px;
  background: #ffffff;
  box-shadow: 0px 8px 12px 0px rgba(30, 58, 129, 0.2);
  border-radius: 10px;
  right: -172px;
  padding: 10px 0 20px;
  top: -50%;
  z-index: 200;
}
.levelBox-list {
  width: 142px;
  height: 42px;
  border-radius: 8px;
  margin: 10px auto 0;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 16px;
  color: #525c76;
  line-height: 42px;
  display: flex;
  align-items: center;
}
.levelBox-list:hover {
  background: rgba(63, 117, 255, 0.1);
}
.levelBox-list img {
  margin: 0px 10px 0 8px;
  width: 20px;
  height: 20px;
}
.codeIcon {
  position: absolute;
  right: 20px;
  font-size: 20px;
}
</style>