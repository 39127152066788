<template>
  <div class="head-main">
    <div class="head-logo">
      <img :src="img.head.logo" alt="">
      <span>
        智慧城市照明监控管理系统
      </span>
    </div>
    <div class="head-right">
      <div class="head-tiem-box">
        <img :src="img.head.cloudy" alt="" style="width:25px;height: 18px;">
        <span>晴天</span>
        <img :src="img.head.sunrise" alt="">
        <span>日出时间：{{environmentInfo.sunRise}}</span>
        <img :src="img.head.sunrise" alt="">
        <span>日落时间：{{environmentInfo.sunSet}}</span>
        <img :src="img.head.time" alt="">
        <span>{{nowTime.date}} {{nowTime.week}} {{nowTime.time}}</span>
      </div>
      <div class="head-user">
        <div class="head-user-info">
          <img :src="img.head.info" alt="">
          <div class="head-police-num">99</div>
        </div>
        <div class="head-stand"></div>
        <div class="head-user-box">
          <img :src="img.head.profile" alt="">
          <Dropdown @on-click="switchMenu">
            <a href="javascript:void(0)" class="head-user-name">
              {{userCon.name}}<Icon type="ios-arrow-down"></Icon>
            </a>
            <template #list>
              <DropdownMenu>
                <DropdownItem name='project'>切换项目</DropdownItem>
                <DropdownItem name='password'>修改密码</DropdownItem>
                <DropdownItem name='out'>退出</DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <ProjectList v-model="showProjectList"></ProjectList>
    <ModifyPassword v-model="showModifyPassword"></ModifyPassword>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ProjectList from '@/pages/auth/ProjectList'
import ModifyPassword from '@/components/auth/signIn/ModifyPassword'
export default {
  name: 'Head',
  components: {
    ProjectList,
    ModifyPassword,
  },
  data() {
    return {
      showProjectList: false,
      showModifyPassword: false,
      ticker: null,
      weeks: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      nowTime: {
        date: '',
        week: '',
        time: '',
      },
      environmentInfo: {
        area: '北京',
        close: '05:31',
        lat: 39.910924,
        lng: 116.413387,
        luxes: [],
        open: '18:59',
        sunRise: '',
        sunSet: ''
      },
      userCon:{},
    }
  },
  created: function () {

  },
  computed: {
    ...mapState('auth', ['token',  'user']),
  },
  watch: {

  },
  mounted: function () {
    this.ticker = setInterval(this.refreshTime, 1000)//
    // this.environment();
    this.userCon=this.user;
  },
  methods: {
    switchMenu(type) {
      switch (type) {
        case 'project':
          this.showProjectList=true;
          break;
        case 'password':
          this.showModifyPassword=true;
          break;
        case 'out':
        this.$router.replace('/');
          break;
      }
    },
    environment: async function () {
      let res = await this.$axios.post(`//${this.domains.trans}/station/home/QueryEnv`, {})
      if (res.code == 0) {
        this.environmentInfo = Object.assign(this.environmentInfo, res.data)
        let sun = new Date(new Date()).sunRiseAndSet(res.data.lat, res.data.lng)
        this.environmentInfo.sunRise = sun.rise
        this.environmentInfo.sunSet = sun.set
      }
    },
    refreshTime: function () {
      let now = new Date()
      this.nowTime.date = now.format('yyyy.MM.dd')
      this.nowTime.week = this.weeks[now.getDay()]
      this.nowTime.time = now.format('HH:mm:ss')
    }
  },
  destroyed: function () {
    if (this.ticker) {
      clearInterval(this.ticker)
      this.ticker = null
    }
  },
}
</script>
<style>
.head-main {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.head-logo img {
  vertical-align: middle;
  margin: 0 30px;
}
.head-logo span {
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 22px;
  color: #222222;
  line-height: 72px;
}
.head-right {
  display: flex;
  align-items: center;
}
.head-tiem-box {
  width: 613px;
  height: 38px;
  background: #f0f5fc;
  border-radius: 19px;
  line-height: 38px;
  color: #55607d;
  display: flex;
  justify-content: space-evenly;
  margin-right: 30px;
}
.head-tiem-box img {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-top: 10px;
}
.head-user {
  display: flex;
  align-items: center;
}
.head-user .head-stand {
  width: 1px;
  height: 26px;
  background: #8f98ae;
  margin: 0 20px 0 30px;
}
.head-user img {
  width: 34px;
  height: 34px;
}
.head-user-name {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #222222;
  margin: 0 10px;
}
.head-user-info {
  display: flex;
  position: relative;
  cursor: pointer;
}
.head-police-num {
  position: absolute;
  height: 16px;
  background: #f40000;
  border-radius: 5px;
  left: 17px;
  top: -7px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 0 5px;

  line-height: 16px;
}
.head-user-box {
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
</style>