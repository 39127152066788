<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">修改密码</div>
    <div>
      <Form ref="form" :model="form" :label-width="100">
        <FormItem prop="name" label="旧密码">
          <Input v-model="form.old" style="width: 270px" type="password" password></Input>
        </FormItem>
        <FormItem prop="code" label="新密码">
          <Input v-model="form.pswd" style="width: 270px" type="password" password></Input>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>

export default {
  name: 'moduleInfoEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      form: {
        "old": "", //旧密码
        "pswd": "" //新密码
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {
  },
  methods: {
    ok() {
      this.$axios.post(`api/auth/ModifyPassword`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
          this.$emit("saved")
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.alarm-data-area {
  width: 100%;
}
</style>
