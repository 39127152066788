<template>
  <div class="site-main" :style="{backgroundImage: `url(${img.head.bigGg})`}">
    <headPage></headPage>
    <div class="home-box">
      <div class="nav-box">
        <transition name="move-left">
          <navBox :list='treeData' @saved="getRouter" v-model="isNavShow" :code='currentCode'></navBox>
        </transition>
      </div>
      <div class="router-box">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import headPage from '@/pages/head'
import navBox from '@/pages/navBox'
export default {
  name: 'indexHome',
  components: {
    headPage,
    navBox
  },
  data() {
    return {
      navList: [
        { name: '可视化', code: 0, img: this.img.nav.navIcon1, actImg: this.img.nav.navIconAc1, router: '', opCode: [], level: [] },
        {
          name: '智慧照明', code: 1, img: this.img.nav.navIcon2, actImg: this.img.nav.navIconAc2, router: '', opCode: [], level: [
            { name: '概览', code: 1, img: '', actImg: '' },
            { name: '配电箱', code: 1, img: '', actImg: '' },
            { name: '灯控', code: 1, img: '', actImg: '' },
            { name: '报警', code: 1, img: '', actImg: '' },
            { name: '配置', code: 1, img: '', actImg: '' },
            { name: '操作', code: 1, img: '', actImg: '' },
          ]
        },
        { name: 'GIS', code: 2, img: this.img.nav.navIcon3, actImg: this.img.nav.navIconAc3, router: '', opCode: [], level: [] },
        { name: '光控', code: 3, img: this.img.nav.navIcon4, actImg: this.img.nav.navIconAc4, router: '', opCode: [], level: [] },
        { name: '集中报警', code: 4, img: this.img.nav.navIcon5, actImg: this.img.nav.navIconAc5, router: '', opCode: [], level: [] },
        { name: '外接电', code: 5, img: this.img.nav.navIcon6, actImg: this.img.nav.navIconAc6, router: '', opCode: [], level: [] },
        { name: '视频', code: 6, img: this.img.nav.navIcon7, actImg: this.img.nav.navIconAc7, router: '', opCode: [], level: [] },
        { name: '漏电', code: 7, img: this.img.nav.navIcon8, actImg: this.img.nav.navIconAc8, router: '', opCode: [], level: [] },
        { name: '防盗', code: 8, img: this.img.nav.navIcon9, actImg: this.img.nav.navIconAc9, router: '', opCode: [], level: [] },
        { name: '水浸', code: 9, img: this.img.nav.navIcon10, actImg: this.img.nav.navIconAc10, router: '', opCode: [], level: [] },
        { name: '智慧灯杆', code: 10, img: this.img.nav.navIcon11, actImg: this.img.nav.navIconAc11, router: '', opCode: [], level: [] },
        { name: 'AI大数据分析', code: 11, img: this.img.nav.navIcon12, actImg: this.img.nav.navIconAc12, router: '', opCode: [], level: [] },
        { name: '运维管理', code: 12, img: this.img.nav.navIcon13, actImg: this.img.nav.navIconAc13, router: '', opCode: [], level: [] },
        { name: '资产管理', code: 13, img: this.img.nav.navIcon14, actImg: this.img.nav.navIconAc14, router: '', opCode: [], level: [] },
        { name: '办公管理', code: 14, img: this.img.nav.navIcon15, actImg: this.img.nav.navIconAc15, router: '', opCode: [], level: [] },
        { name: '系统管理', code: 15, img: this.img.nav.navIcon16, actImg: this.img.nav.navIconAc16, router: '/index/auth', opCode: [], level: [] },
        { name: '生产管理', code: 16, img: this.img.nav.navIcon17, actImg: this.img.nav.navIconAc17, router: '', opCode: [], level: [] },
        { name: '平台管理', code: 17, img: this.img.nav.navIcon18, actImg: this.img.nav.navIconAc18, router: '/index/platform', opCode: [], level: [] },
      ],
      isNavShow: true,
      currentCode: '',
      treeData: [],
    }
  },
  created: function () {
    this.$store.commit('auth/init');
    this.$store.commit('sys/init');
  },
  computed: {
    ...mapState('auth', ['token', 'user']),
    ...mapState('sys', ['navData', 'myMenus', 'isSiteNav']),
  },
  watch: {

  },
  mounted: function () {
    this.$store.dispatch('sys/getMyMenus')
    this.treeNav();
  },
  methods: {
    treeNav() {
      let arr = this.myMenus.modules;
      arr.forEach(el => {
        let nva = this.navData.find(item => item.code == el.code);
        if (nva) {
          el.img = nva.img
          el.actImg = nva.actImg
          el.router = nva.router;
          el.series = nva.series*1;
          if (this.$route.path == el.router) {
            this.currentCode = el.series;
          }
        }
      });
      if (this.myMenus.isSite) {
        arr = [...arr, ...this.isSiteNav]
      }
      this.treeData = this.buildTree(arr);
      if (this.$route.path == '/index') {
        this.currentCode = this.treeData[0].series;
        this.getRouter(this.treeData[0])
      }
    },
    buildTree(items, parentId = '') {
      return items.filter(item => item.parentId === parentId).map(item => ({ ...item, children: this.buildTree(items, item.id), }));
    },
    getRouter(el) {
      this.currentCode = el.series;
      if (el.router != '') {
        this.$router.push(`${el.router}`);
      }
    },
  },
}
</script>
<style>
.site-main {
  width: 100%;
  min-width: 1920px;
  height: 100%;
  background-size: 100% 100%;
}
.home-box {
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
}
.nav-box {
  width: 294px;
  /* width: 98px; */
}
.router-box {
  width: calc(100% - 294px);
  height: 100%;
}
</style>